import { range } from "lodash-es"
import { currentTimestamp } from "@/config/store/StoreItemsModule/helpers"

export const DEFAULT_LAYOUT = "default"
export const SIMPLE_LAYOUT = "simple"
export const AUTH_LAYOUT = "auth"
export const TOKEN_REFRESH_IDLE_TIME = 10 * 60 * 1000 // 10 minutes

export const CURRENT_API_VERSION = 1
const isLocalhost = window.location.host.includes("localhost")
const liveWss = `wss://${window.location.host}/websocket`
const localApiUrl = `${process.env.VUE_APP_API_URL}/api/v${CURRENT_API_VERSION}`
const liveApiUrl = `https://${window.location.host}/api/v${CURRENT_API_VERSION}`

export const BASE_API_URL = isLocalhost ? localApiUrl : liveApiUrl
export const WEBSOCKET_URL = isLocalhost ? process.env.VUE_APP_WS_URL : liveWss
export const ESC_KEY_CODE = 27
export const ENTER_KEY_CODE = 13
export const ZERO_KEY_CODE = 48
export const NINE_KEY_CODE = 57

export const DEFAULT_TIMEZONE = "Asia/Tokyo"
export const DATE_FORMAT = "YYYY/MM/DD"
export const DATE_SHORT_DATE_FORMAT = "MM/dd"
export const DATE_FNS_DATE_FORMAT = "yyyy/MM/dd"
export const DATE_FNS_DATE_FORMAT_DASH = "yyyy-MM-dd"
export const DATE_FNS_SHORT_DATE_FORMAT = "MM/d"
export const DATE_FNS_MONTH_FORMAT = "yyyy/MM"
export const EMAIL_REGEXP = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
export const PHONE_NUMBER_REGEXP = /^[0-9-+)( ]{10,20}$/
export const ORGANIZATION_NAME_REGEXP = /^[\w\d. -]*$/
export const ACCOUNT_FORBIDDEN_SYMBOLS_REGEXP = /[^\w.-]/g

export const PERMISSION_READ = "read"
export const PERMISSION_EDIT = "edit"
export const PERMISSION_DENY = "deny_all"

export const MAIL_SUPPORT = process.env.MAIL_SUPPORT || "metroconductor_support@metroengines.jp"

export const ALERT_TIMEOUT = 10000
export const RESET_PASSWORD_REDIRECT_TIMEOUT = 5000

export const SHOPS_MAX_COUNT = 500
export const SERIALIZER_VIEW_EXTENDED = "extended"
export const SERIALIZER_VIEW_RESEND_MODAL = "resend_modal"
export const SERIALIZER_VIEW_FILTERS = "filters"
export const SERIALIZER_VIEW_FOR_RULES = "for_rules"
export const SERIALIZER_VIEW_SHORT = "short"

export const OTA_WITH_MASTER_SHOP = [
  "jalan",
  "rakuten",
  "tabirai",
  "tabiraku",
  "skyticket",
  "tabiplaza",
  "rennavi",
  "his",
  "aeon"
]

export const NICOLAS_OTA_RAW_NAME = "nicolas"

export const OTAS_WITH_COMPANY_SYSTEM = ["c_his", "hotel_official", "air_trip"]

export const DAYS_OPTIONS_ORDER = [
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
  "sunday",
  "holiday",
  "tomorrow_holiday"
]

export const REVENUE_ANALYSIS_DATA_PORTAL_LINK = process.env.VUE_APP_REVENUE_ANALYSIS_DATA_PORTAL_LINK

// rental periods daily
export const RP_DAILY_1_DAY = "day1"
export const RP_DAILY_OVERNIGHT = "overnight"
export const RP_DAILY_2_DAYS = "days2"
export const RP_DAILY_3_DAYS = "days3"
export const RP_DAILY_LATE = "late"

// rental periods
export const RP_HOURLY_1_6 = "hours1_6"
export const RP_HOURLY_7_12 = "hours7_12"
export const RP_HOURLY_13_24 = "hours13_24"
export const RP_HOURLY_LATE = "hourly_late"
export const RP_HOURLY_LATE_DAILY = "late_daily"

export const APP_DATEPICKER_START_DATE = 0
export const APP_DATEPICKER_END_DATE = 1
export const AUTO_SET_INVENTORY_INITIATOR_TYPE = "AutoSetInventory"
export const SALE_STOP_SETTING_INITIATOR_TYPE = "SaleStopSetting"

// Basic settings templates
export const SAVE_AS_NEW_TEMPLATE = "save_as_new_template"
export const OVERWRITE_TEMPLATE = "overwrite_template"
export const DO_NOT_SAVE = "do_not_save"

// Basic Settings Closing and Sales Start Date options
export const NO = "no"
export const MATCH = "match"
export const BUSINESS_HOURS = "hourly_consider_business_hours"
export const NOT_BUSINESS_HOURS = "hourly_ignore_business_hours"
export const DEADLINE_IGNORE_HOLIDAYS = "deadline_ignore_holidays"
export const DEADLINE_NOT_IGNORE_HOLIDAYS = "deadline_consider_holidays"

export const BUSINESS_HOURS_OPTIONS = [BUSINESS_HOURS, NOT_BUSINESS_HOURS]
export const DEADLINE_HOLIDAYS_OPTIONS = [DEADLINE_IGNORE_HOLIDAYS, DEADLINE_NOT_IGNORE_HOLIDAYS]

export const DISPLAY_IMPORTANCE_OPTIONS = range(1, 11).map(i => ({ id: i, name: i.toString() }))

// Basic Settings Reservation Availability Period
export const DEFAULT = "default"
export const END_DATE = "end_date"
export const START_END_DATE = "start_end_date"

// Basic Settings Points
export const DOUBLE_POINTS = "times_2"
export const FIVE_TIMES_POINTS = "times_5"

// Basic Settings fees options
export const NO_FEE = "no_fee_settings"
export const FIXED_FEE = "fixed_fee"
export const PERIOD_SPECIFIED_FEE = "period_specified_fee"
export const CALENDAR_DAY_FEE = "calendar_day_fee"
export const EVERY_DAY = "every_day"
export const EVERY_24_HOURS = "every_24_hours"

export const FEE_OPTIONS = [FIXED_FEE, CALENDAR_DAY_FEE, EVERY_DAY, EVERY_24_HOURS]
export const MAX_OPTIONS = [PERIOD_SPECIFIED_FEE, CALENDAR_DAY_FEE, EVERY_DAY, EVERY_24_HOURS]

export const STANDARD = "standard"
export const SELECTABLE = "selectable"

export const PRICE_MANAGEMENT_ALLOWED_OTA_IDS = {
  jalanId: 1,
  rakutenId: 2
}

export const DISABLED = "disabled"
export const RATE_CHECK_MODE = "rate_check_mode"
export const MANUAL_MODE = "manual_mode"
export const AUTO_PRICE_MODE = "auto_price_mode"
export const AI_MODE = "ai_mode"

export const OPTIONAL = "optional"
export const INCLUDED = "included"

//By period settings
export const EMPTY_TARGET_PERIOD_OBJECT = (_jalan_plan_id, _rakuten_plan_id) => ({
  _addedAt: currentTimestamp(),
  _jalan_plan_id,
  _rakuten_plan_id,
  plan_name: "",
  plan_name_mobile: "",
  jalan_points: undefined,
  rakuten_points: 0,
  plan_code: "",
  date_period: {
    start_date: {
      year: "",
      month: "",
      day: ""
    },
    end_date: {
      year: "",
      month: "",
      day: ""
    }
  }
})

export const COMPETITORS_DEFAULT_SORT_ORDER = "shop_name"

export const ON = "on"
export const OFF = "off"

// currencies
export const JPY = { code: "JPY" }

// markup types
export const PERCENT_MARKUP = { id: "percent", name: "%" }
export const FIXED_MARKUP = { id: "fixed", name: "JPY" }

export const CALCULATED = "calculated"
export const CURRENT = "current"

export const AUTO_UPDATE_TIME = { value: 2, abbr: "am" }
export const METRODIRECT_OTA = "metrodirect"

export const OVERBOOKING_OCCURS = "overbooking_occurs"
export const REMAINING_INVENTORY_ZERO = "remaining_inventory_zero"
export const REMAINING_INVENTORY_DECREASE = "remaining_inventory_decrease"

export const PERMISSION_PAGES_ORDER = [
  "inventory_management",
  "siffd",
  "siflp",
  "manual_inventory_import",
  "resend_inventory",
  "price_calendar",
  "mode_settings",
  "competitors_settings",
  "rule_settings",
  "plan_settings",
  "competitor_prices",
  "ota_setting",
  "shop_matching",
  "car_class_matching",
  "shops_sources_car_matching",
  "shops_car_class_matching",
  "inventory_group_matching",
  "inventory_group_car_class_matching",
  "inventory_notification_settings",
  "notifications",
  "logs",
  "account_list",
  "permission_settings",
  "create_account"
]

export const MAX_SELECT_COUNT_CAR_CLASSES = 25

export const TOYOTA_ORDER = {
  rakuten: 1,
  jalan: 2,
  tabirai: 3,
  tabiraku: 4,
  skyticket: 5,
  tabiplaza: 6,
  others: 7
}

export const ROUTES_WITH_STICKY_HEADERS = ["shared_inventory_management", "inventory_management"]
